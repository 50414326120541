// dashboard styles
.dashboard-container {
	position: relative;
}

.dashboard-content {
	@extend .px-3;
	display: flex;
	flex-direction: column;
}

.category-container {
	@extend .my-2;
	@extend .me-2;
	display: flex;
	flex-direction: column;
	border-radius: 1rem;
	background-color: $light;
}

.category-label {
	@extend .p-2;
	@extend .fw-bold;

}

.item-plate {
	@extend .m-2;
	@extend .p-2;
	@extend .border;

	border-radius: 0.5rem;
	background-color: #E7F0F5;
	word-break: normal;
	border-color: var(--royal-blue) !important;
	border-width: 2px !important;
}

.item-label {
	color: var(--royal-blue);
	font-weight: bold;
}

.item-unit {
	@extend .fw-bold;
	@extend .ps-1;
}

.item-valuetype-short {
	
	max-width: 10rem;
	overflow: hidden;
	text-transform: capitalize;
}

.item-valuetype-long {
	
	max-width: 10rem;
	overflow: hidden;
	text-transform: capitalize;
	font-size: 0.7rem;
	min-height: 1rem;
}

.item-value {
	
	@extend .fw-bold;
	@extend .p-0;
}

.item-value-unit {
	@extend .text-center;
	
	display: flex;
	justify-content: center;
	align-items: center;
}


.item-plate-content {
	display: flex;
}

.category-content {
	display: flex;
	flex-direction: column;
}

.item-label-slot {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}


.dashboard-map-container {
	@extend .vh-100;
	@extend .mt-5;
	@extend .p-5;
	width: 100%;
	background-color:$light;
	border-radius: 1rem;
}

.dashboard-map {
	border-radius: 1rem;
}

@media (min-width: 576px) {

	.dashboard-content {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;

	}

	.category-content {
		flex-direction: row;
		overflow: scroll;
		max-width: 560px;
	}

	.item-plate {
		max-width: 12rem !important;
		min-width: 12rem !important;
		height: 10rem;
		border-radius: 1rem;
		background-color: #E7F0F5;
		word-break: normal;
		border-color: var(--royal-blue) !important;
		border-width: 2px !important;
	}

	.item-plate-content {
		display: flex;
		flex-direction: column;
		height: 100%;
		justify-content: space-between;
		align-items: center;
	}

	.item-label {
		max-width: 10rem;
		display: -webkit-box;
		-webkit-line-clamp: 3;
		/* Number of lines */
		line-clamp: 3;
		/* Number of lines */
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;
		text-align: center;
	}

	.item-menu-container {
		margin-left: -0.5rem;
		margin-right: -1rem;
		margin-top: -0.5rem;
	}

	.item-valuetype-long {
		text-align: center;
		max-width: 10rem;
		font-size: 0.6rem;
	}

	.item-valuetype-short {
		text-align: center;
		max-width: 10rem;
	}

	.item-value-unit {
		@extend .text-center;
		max-width: 10rem !important;
		display: flex;
		justify-content: center;
		align-items: center;
		padding-bottom: 0.5rem;
	}

	.item-value {
		font-size: 1.25em;
	}

	.item-unit {
		text-align: center;
	}

}

@media (min-width: 768px) {
	.category-content {
		flex-direction: row;
		overflow: scroll;
		max-width: 768px;
	}

}

@media (min-width: 992px) {
	.category-content {
		flex-direction: row;
		overflow: auto;
		max-width: 992px;
	}

}


@media (min-width: 1200px) {
	.category-content {
		flex-direction: row;
		overflow: auto;
		max-width: 1200px;
	}

}



@media (min-width: 1400px) {
	.category-content {
		flex-direction: row;
		overflow: auto;
		max-width: 1400px;
	}

}

@media (min-width: 1800px) {
	.category-content {
		flex-direction: row;
		overflow: auto;
		max-width: 100%;
	}

}