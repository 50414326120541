@charset "UTF-8";

.data-product-details-section {
	border-style:solid;
	border-width:1px;
}

.data-product-details-section > H4 {
	margin: 0.5rem;
	padding: 2px;
	text-align: "center";
	background-color: var(--light);
}