@charset "UTF-8";

.validDateTime {
	border-width:2px;
}

.invalidDateTime {
	border-width: 2px;
	border-color: red;
	border-style: solid;
	
}