@charset "UTF-8";

.aoieditor {
	height:30rem;
	width: 40rem;
}

.aoieditor-mobile {
	min-width: 10rem;
	height:30rem;
	width: 100%;
}