// Override default variables before the import
$body-bg: #ffffff;
$light: #D0CECE;
$dark: #000000;
$secondary: $light;
$secondaryBg: #D0CECE;
$primary: #AFABAB;
$primaryBg: #747691;
$tertiary: #D0709E;

$success: #1E8077;
$info: #5A968E;
$warning: #FABE5D;
$danger: #B7423F;

$quartery: #7390C5;

$navbar-light-color: $dark;
$table-caption-color: $dark;

$theme-colors: (
	"primary": $primary,
	"tertiary": $tertiary,
	"secondary": $secondary,
	"primaryBg": $primaryBg,
	"secondaryBg": $secondaryBg,
	"eo": #d1e6d3,
	"insitu": #adb4ce
);

$link-color: $dark;
$code-color: $quartery;

$popover-max-width: "600px";

$cc-col1-op: rgba(115, 143, 196, 0.3);
$cc-col2-op: rgba(195, 145, 34, 0.3);
$cc-col3-op: rgba(40, 127, 118, 0.3);
$cc-col4-op: rgba(250, 190, 93, 0.3);
$cc-col5-op: rgba(208, 112, 158, 0.3);
$cc-col6-op: rgba(184, 67, 63, 0.3);
$cc-col7-op: rgba(82, 87, 101, 0.3);
$cc-col8-op: rgba(74, 81, 134, 0.3);
$cc-col9-op: rgba(238, 127, 0, 0.3);
$cc-col10-op: rgba(0, 134, 203, 0.3);
$cc-col11-op: rgba(32, 102, 94, 0.3);

//for all variables, see 
//@import '~bootstrap/scss/_variables.scss';

// Import Bootstrap and its default variables
@import 'bootstrap/scss/bootstrap.scss';

@import 'leaflet/dist/leaflet.css';

// general / global

// scale font sizes
html {
	font-size: 1rem;
}

@include media-breakpoint-up(sm) {
	html {
		font-size: 1rem;
	}


}

@include media-breakpoint-up(md) {
	html {
		font-size: 1.0rem;
	}



}

@include media-breakpoint-up(lg) {
	html {
		font-size: 1.0rem;
	}


}

@include media-breakpoint-up(xl) {
	.data-product-list {
		overflow-y: auto;
		height: 70rem;
		max-height: calc(100vmin - 15rem);
		min-height: 25rem;
	}

	.section-top {
		height: 3rem;
	}
}

// other

.welcome-page {
	background-image: url(https://www.petabite.eu/img/S2A_MSIL1C_Clear.jpg);
	background-repeat: no-repeat;
	background-size: cover;
}

.welcome-page-content {
	color: $dark;
	background: #eeeeeeed
}

.feature-card {
	background-color: white;
	opacity: 1.0;
}

.function-area-Discovery {
	background-color: $cc-col1-op;
}

.function-area-Data {
	background-color: $cc-col2-op;
}

.function-area-Storage {
	background-color: $cc-col3-op;
}

.function-area-Processing {
	background-color: $cc-col4-op;
}

.function-area-Access {
	background-color: $cc-col5-op;
}

.function-area-Free {
	background-color: green;
}


.notused {
	background-color: lightgrey !important;
}


.dialogue {
	background-color: $body-bg;
}

// pastell colors: #B9BDB1, #D6D5C3, #FFF0E0, #F7D7D7, #D9C3D2

.filter-intervalStart {
	background-color: #FFF0E0;
	font-size: 0.9rem;
}

.filter-intervalEnd {
	background-color: #D6D5C3;
	font-size: 0.9rem;
}

.filter-productTypes {
	background-color: #B9BDB1;
	font-size: 0.9rem;
}

.filter-name {
	background-color: #F7D7D7;
	font-size: 0.9rem;
}

.filter-aoi {
	background-color: #D9C3D2;
	font-size: 0.9rem;
}

.filter-noOfOtherConditions {
	background-color: #D3F0E0;
	font-size: 0.9rem;
}

path.aoibox {
	stroke: black;
}

// for documentation type pages

.doc-page {
	@extend .p-0;
	background-color: $body-bg;
}

.doc-page-header {
	@extend .p-1;
	@extend .text-center;
	// ensure header has same height in adjacent components
	height: 4rem;
	background-color: #C1607A;
}

.doc-page-content {
	@extend .p-5;
}

.doc-figure {
	@extend .p-2;
	@extend .justify-content-center;
}

.helper-icon {
	background-color: $primaryBg;
}

// switch off spinners as they sometimes hide the number they are modifying
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type=number] {
	-moz-appearance: textfield;
}

.selected-footprint {
	//FIXME: make SASS work
	stroke: $tertiary;

}

.simple-map {
	min-height: 300px;
}

.mapview {
	height: calc(100% - 5rem);
}

.thingpositionmap {
	height: 400px;
	width: 400px;
	max-width: 400px;
}

.leaflet-popup-content-wrapper {
	background-color: white !important;
	
}

.__json-pretty__ {
	line-height: 1.3;
	color: blue;
	background: white;
	overflow: auto
}

.__json-key__ {
	color: blue;
}

.__json-value__ {
	color: black;
}

.__json-string__ {
	color: black;
}

.__json-boolean__ {
	color: rgba(102, 153, 204, 1);
}

.__json-pretty-error__ {
	line-height: 1.3;
	color: rgba(248, 248, 242, 1);
	background: #1e1e1e;
	overflow: auto;
}


@import 'dashboard'

